import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

function Footer() {
  const data = useStaticQuery(graphql`
    query {
      footerImage: file(relativePath: { eq: "bg-footer.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logoImage: file(relativePath: { eq: "goosefoot-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const footerImage = data.footerImage.childImageSharp.fluid
  const logoImage = data.logoImage.childImageSharp.fluid

  return (
    <footer className="grid grid-rows-1 items-center" style={{maxHeight: '600px'}}>
      <div className="grid-area-11 z-0 flex" style={{minHeight: '100%'}}>
        <Img className="flex-100" fluid={footerImage} loading="lazy" />
      </div>
      <div className="grid-area-11 z-10 py-8 lg:py-4 self-end mx-auto" style={{maxWidth: '1200px'}}>
        <Img
          className="w-50vw lg:w-30vw mx-auto"
          fluid={logoImage}
          loading="lazy"
        />
        <div className="grid grid-cols-2 md:grid-cols-4 items-center gap-4 mt-6 mb-4 md:mb-2 justify-center z-10">
          <a
            className="px-5 text-md font-serif font-bold text-white text-center"
            href="https://goo.gl/maps/pBoqbdWQiLkCE5WJA"
          >
            Office Directions
          </a>
          <a
            className="px-5 text-md font-serif font-bold text-white text-center"
            href="https://www.goosefoot.org/board-staff"
          >
            Board & Staff
          </a>
          <a
            className="px-5 text-md font-serif font-bold text-white text-center"
            href="https://www.goosefoot.org/grants"
          >
            Donate
          </a>
          <a
            className="px-5 text-md font-serif font-bold text-white text-center"
            href="mailto:info@goosefoot.org"
          >
            Contact
          </a>
        </div>
        <p className="font-serif text-white text-sm text-center tracking-tighter z-10">
            © Goosefoot 2020. All Rights Reserved
        </p>
      </div>
    </footer>
  )
}

export default Footer
