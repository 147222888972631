import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

function Header(props) {
  const data = useStaticQuery(graphql`
    query {
      farmstandImage: file(relativePath: { eq: "header.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const farmStandLogo = data.farmstandImage.childImageSharp.fluid
  return (
    <Link className={props.className} to="/">
      <Img fluid={farmStandLogo} loading="eager" alt="Whidbey Island Roadside Farm Stands, Farm Stores, and Farmer's Markets" critical />
    </Link>
  )
}

export default Header
